import React, { useState } from "react";
import isBrowser from "../../../utils/isBrowser";
import locationMarkerIcon from "../../../assets/images/locationMarker.svg";

// import L from "leaflet";
const L = typeof window !== "undefined" ? require("leaflet") : {};
// import { Marker, useMapEvents } from "react-leaflet";
const { Marker, useMapEvents } =
    typeof window !== "undefined" ? require("react-leaflet") : {};

export const LocationMarker = (() => {
    if (typeof window !== "undefined") {
        const icon = new L.Icon({
            iconUrl: locationMarkerIcon,
            iconRetinaUrl: locationMarkerIcon,
            iconSize: new L.Point(48, 48),
            className: "location-marker-icon",
        });

        return (...props) => {
            const [position, setPosition] = useState(null);
            const map = useMapEvents({
                locationfound(e) {
                    setPosition(e.latlng);
                    map.flyTo(e.latlng, map.getZoom());
                },
            });

            if (position !== null)
                return <Marker position={position} icon={icon} />;
            else return null;
        };
    } else return null;
})();

export default LocationMarker;
