import React, { useRef, useState, useEffect } from "react";

import image1 from "../../../assets/images/tutorialPicture1.gif";
import image2 from "../../../assets/images/tutorialPicture2.gif";
import image3 from "../../../assets/images/tutorialPicture3.gif";
import image4 from "../../../assets/images/tutorialPicture4.gif";

// STYLE
import "./routeTutorialModal.scss";

// COMPONENTS
import Modal from "../Modal/Modal";
import SlickSlider from "../../atoms/SlickSlider/SlickSlider";

const RouteTutorialModal = ({ show, setShow }) => {
    const sliderRef = useRef();
    const [currentSlide, setCurrentSlide] = useState(0);
    const slidesCount = 4;
    let dots = [];

    for (let index = 0; index < slidesCount; index++) {
        dots.push(
            <span
                key={index}
                className={`${currentSlide === index ? "active" : ""}`}
                onClick={() => sliderRef.current?.slickGoTo(index)}
            ></span>
        );
    }

    useEffect(() => {
        setCurrentSlide(0);
    }, [show]);

    return (
        <Modal
            className="modal--route-tutorial pb-0"
            modal={show}
            setModal={() => setShow(false)}
            BottomActions={() => (
                <div className="modal--route-tutorial--footer">
                    <div className="dots">{dots}</div>

                    <div className="controls">
                        <div onClick={() => setShow(false)}>Pomiń</div>
                        {currentSlide === slidesCount - 1 ? (
                            <div onClick={() => setShow(false)}>Start</div>
                        ) : (
                            <div onClick={() => sliderRef.current.slickNext()}>
                                Dalej
                            </div>
                        )}
                    </div>
                </div>
            )}
        >
            <SlickSlider
                innerRef={sliderRef}
                settings={{
                    infinite: false,
                    fade: true,
                    beforeChange: (current, next) => {
                        setCurrentSlide(next);
                    },
                }}
            >
                <div className="modal--route-tutorial-item">
                    <div className="modal--route-tutorial-item-picture">
                        <img src={image1} />
                    </div>
                    <div className="modal--route-tutorial-item-heading">
                        Jak wyruszyć w trasę?
                    </div>
                    <div className="modal--route-tutorial-item-desc">
                        "Przed wyruszeniem w drogę należy zebrać drużynę" :) Na
                        mapie zaznaczyliśmy kilka tras, które różnią się od
                        siebie poziomem trudności (np. kolor czerwony - trasa
                        trudna) oraz liczbą wyzwań, historii i ćwiczeń. Wybierz
                        trasę odpowiednią dla siebie i Twoich kompanów.
                        Startujemy!
                    </div>
                </div>

                <div className="modal--route-tutorial-item">
                    <div className="modal--route-tutorial-item-picture">
                        <img src={image2} />
                    </div>
                    <div className="modal--route-tutorial-item-heading">
                        Gdzie jestem?
                    </div>
                    <div className="modal--route-tutorial-item-desc">
                        Trasę, którą wybrałeś możesz rozpocząć i przerwać w
                        dowolnym momencie. Jeśli jesteś zarejestrowany, Twoje
                        osiągnięcia zapiszą się w aplikacji. Dzięki dostępowi do
                        Twojej lokalizacji pomożemy Ci odnaleźć się na mapie,
                        ale nie będziemy Cię nawigować - to Ty jesteś odkrywcą!
                    </div>
                </div>

                <div className="modal--route-tutorial-item">
                    <div className="modal--route-tutorial-item-picture">
                        <img src={image3} />
                    </div>
                    <div className="modal--route-tutorial-item-heading">
                        Czym są wyzwania?
                    </div>
                    <div className="modal--route-tutorial-item-desc">
                        Na mapie znajdziesz punkty z wyzwaniami. Czasem będzie
                        to zadanie do wykonania, czasem historia do
                        przeczytania, a innym razem ciekawostka do odkrycia.
                        Wszystko w imię dobrej zabawy i podzielenia się z Tobą
                        wiedzą o regionie. Po dotarciu na miejsce wystarczy
                        aktywować zadanie.
                    </div>
                </div>

                <div className="modal--route-tutorial-item">
                    <div className="modal--route-tutorial-item-picture">
                        <img src={image4} />
                    </div>
                    <div className="modal--route-tutorial-item-heading">
                        Zostań Mistrzem Przygód
                    </div>
                    <div className="modal--route-tutorial-item-desc">
                        Jeżeli zarejestrujesz się w naszej aplikacji, Twoje
                        pokonane trasy oraz wykonane wyzwania zapiszą się i
                        zostaną nagrodzone "punktami odkrywcy". Dzięki nim
                        możesz rywalizować z innymi oraz zostać Mistrzem Przygód
                        :) Powodzenia!
                    </div>
                </div>
            </SlickSlider>
        </Modal>
    );
};

export default RouteTutorialModal;
